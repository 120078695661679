@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.linear-gradient {
  background:
    linear-gradient(
      179.14deg,
      rgba(32, 18, 63, 0) -7.14%,
      #000 87.01%
    );
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.8);
}
